
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import plans from "@/core/data/plan";
import { IPlan } from "@/core/data/plan";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    VPagination,
    // AddCustomerModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const checkedPlan = ref([]);
    const showTable = ref(false);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Amount",
        key: "amount",
        sortable: true,
      },
      {
        name: "Number",
        key: "number_limit",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IPlan>>([]);
    const initCustomers = ref<Array<IPlan>>([]);

    onMounted(() => {
      const userData = localStorage.getItem("userdata");
      if (userData) {
        var allData = JSON.parse("" + userData + "");
        if (allData.type == "admin") {
          setCurrentPageBreadcrumbs("Plan", []);
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          // console.log('tableData',tableData.value);
          getItem(1);
        } else {
          router.push({ name: "dashbord" });
        }
      }
    });
    const limit = ref(10);
    const total_pages = ref(1);
    const getItem = (page) => {
      var request = {
        url: `/plan2?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteFewPlan = () => {
      checkedPlan.value.forEach((item) => {
        deletePlan(item);
      });
      checkedPlan.value.length = 0;
    };

    const deletePlan = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `plan2/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            showTable.value = false;
            Swal.fire("Deleted!", "Plan has been deleted.", "success");
            getItem(1);
          });
          // Swal.fire("Deleted!", "Plan has been deleted.", "success");
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IPlan> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };
    const page = ref(1);
    return {
      tableData,
      tableHeader,
      deletePlan,
      search,
      searchItems,
      checkedPlan,
      deleteFewPlan,
      getItem,
      dateTime,
      showTable,
      total_pages,
      updateHandler,
      page
    };
  },
});
